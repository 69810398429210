.main-wrapper {
  padding-top: 16px;
}

.items-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inner-box {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-between;
}

.counter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.num-box {
  width: 100px;
  height: 40px;
  border: 1px solid #229ed9;
  color: #229ed9;
  display: flex;
  justify-content: center;
  font-size: 24px;
  border-left: none;
  border-right: none;
}

.inc-box {
  height: 40px;
  color: #229ed9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #229ed9;
  border-left: none;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.dec-box {
  height: 40px;
  color: #229ed9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #229ed9;
  border-right: none;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.d-flex {
  display: flex;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button {
  height: 40px;
  width: 100%;
  border-radius: 50px !important;
}

.item-image {
  transition: width 2s, height 2s, transform 2s;
}
.item-image:hover {
  transform: scale(1.1);
}

.discount-badge {
  position: absolute;
  top: 6px;
  left: 0;
  /* background-color: #e3b625; */
  background-color: #B52B2B;
  padding: 3px 10px 0px 5px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  z-index: 1;
  color: white;
  font-weight: 600;
}

.page-number {
  margin-top: 3px;
  width: 26px;
  height: 26px;
  padding: 0px 9px;
  border: 1px solid #ededed;
  border-radius: 4px;
  background-color: #fff;
}

.select-height {
  height: 28px;
  border-radius: 4px;
}

.footer-result{
  margin-top: 3px;
  margin-left: 10px;
}