.side-bar {
  min-width: 250px;
  background-color: #fefefe;
  height: 100vh;
}

.close-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #E3B625;
  height: 64px;
}

.font {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0px 5px 15px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #000;
}

.font:hover {
  background-color: #e2e6ea;
}
