/* Customize navigation arrows with transition for hover effects */
.slide-container .nav {
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.slide-container .nav:hover {
  transform: translateY(-50%) scale(1.1); /* Slightly enlarge on hover */
}

/* Custom styles for navigation arrows */
.slide-container .nav:first-of-type,
.slide-container .nav:last-of-type {
  background-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Indicate that these are clickable */
}

/* Specific customizations for the "previous" arrow */
.slide-container .nav:first-of-type {
  left: 6px;
}

.slide-container .nav:first-of-type::before {
  font-size: 16px;
  color: black;
}

/* Specific customizations for the "next" arrow */
.slide-container .nav:last-of-type {
  right: 6px;
}

.slide-container .nav:last-of-type::before {
  font-size: 16px;
  color: rgb(0, 0, 0);
}

/* Customize dot indicators with a transition effect */
.slide-container .indicators {
  bottom: -25px;
  transition: opacity 0.3s ease; /* Fade effect for indicators */
}

.slide-container .indicators .each-indicator {
  background-color: #ccc; /* Default state color */
  transition: background-color 0.3s ease; /* Color transition for active state */
}

.slide-container .indicators .each-indicator.active {
  background-color: #333; /* Active state color */
}
