.card-box {
  display: flex;
  align-items: center;
}

.d-flex {
  display: flex;
}

.sub-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
}

.typo-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  min-width: 60%;
  gap: 10px;
}

.cart-items-wrapper {
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

.quantity-wrapper {
  min-width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.num-wrapper {
  min-width: 40px;
  max-width: 80px;
  height: 40px;
  border: 1px solid #229ed9;
  color: #229ed9;
  display: flex;
  justify-content: center;
  font-size: 24px;
  border-left: none;
  border-right: none;
}

.inc-wrapper {
  height: 40px;
  color: #229ed9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #229ed9;
  border-left: none;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.dec-wrapper {
  height: 40px;
  color: #229ed9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #229ed9;
  border-right: none;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.cross-cart {
  position: absolute !important;
  top: 0px;
  right: 0px;
}

.summary-item-details {
  width: 100%;
  display: flex;
}

.summary-header {
  background-color: #e1f5d7;
}

.summary-item-product {
  width: 50%;
  padding: 7px 15px 5px;
  color: rgba(0, 0, 0, 0.6);
}

.summary-item-qty {
  width: 20%;
  padding: 7px 15px 5px;
  color: rgba(0, 0, 0, 0.6);
}

.summary-item-price {
  width: 30%;
  padding: 7px 15px 5px;
  color: rgba(0, 0, 0, 0.6);
}

.summary-card {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}

.summary-title-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary-divider-line {
  width: 100%;
  margin: 0px !important;
}

.summary-total-title {
  width: 70%;
  padding: 7px 15px 5px;
  background-color: #e1f5d7;
}

.summary-total-price {
  width: 30%;
  padding: 7px 15px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500 !important;
}

.order-remarks {
  padding: 15px;
}

.profile-info {
  padding: 15px;
}

.delivery-info {
  padding: 15px;
}

.address-info {
  padding: 15px;
  width: 100%;
}

.info-box {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.address-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-label-wrapper {
  padding: 10px;
  border-radius: 4px;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.default-tag {
  padding: 0px 10px;
  border-radius: 50px;
}
