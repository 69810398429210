/* Container styles */
.slider-container {
  position: absolute;
  left: 0px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  /* Adjust the container size as needed */
  width: 100%;
  height: 30px;
  background-color: #e3b62540;
  display: flex;
  align-items: center;
}

/* Sliding content styles */
.slider-content {
  display: inline-block;
  /* Ensure there's enough space for your content to complete the loop smoothly */
  padding-left: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #2b040d;
  /* Animation */
  animation: slide-infinite 30s linear infinite;
}

/* Keyframes for the sliding animation */
@keyframes slide-infinite {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
