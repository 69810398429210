.profile-links {
  text-decoration: none;
}

.start {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.profile-grid {
  padding: 20px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.profile-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profile-avatar {
  margin-top: 10px;
  width: 150px !important;
  height: 150px !important;
  font-size: 80px !important;
  font-weight: 600 !important;
  background-color: #e3b625 !important;
}

.link-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #dee2e6;
}

.link-start:hover {
  color: #e3b625;
}

.link-start p:hover {
  color: #e3b625;
}
