* {
  font-family: "Baloo Bhaijaan 2", cursive;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: #f8f9fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e3b625;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e3b625;
  }
}

body {
  margin: 0;
  font-family: "Baloo Bhaijaan 2", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Baloo Bhaijaan 2", cursive;
}
